footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.875rem;
  img {
    width: 5%;
  }
  div {
    font-size: 1.4rem;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1024px) {
    img {
      width: 10%;
    }
    div {
      font-size: 1.5625rem;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    img {
      width: 20%;
    }
    div {
      margin-top: 1remrem;
      font-size: 1.25rem;
      text-align: center;
    }
  }
}
