.AppLink {
  padding: 1.875rem;
  border-radius: 1.5625rem;
  min-height: 11.25rem;
  font-size: 2.5rem;
  cursor: pointer;
  transition: 0.15s all ease-in-out;

  &:hover {
    transform: scale(0.98);
    transition: 0.2s all ease-in-out;
  }
  .content {
    padding-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 20px;
    min-height: 6.25rem;
    font-size: 1.5rem;
    .content {
      span {
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.3rem;
    .content {
      span {
        font-size: 1.4rem;
      }
    }
  }
}

// bg colors
.AppLink.light {
  background-color: var(--light);
  color: var(--dark-2);
  @media screen and (max-width: 1024px) {
    img {
      width: 80%;
    }
  }
}
.AppLink.dark {
  background-color: var(--dark-1);
  color: var(--light);
  @media screen and (max-width: 1024px) {
    img {
      width: 80%;
    }
  }
}
.AppLink.primary {
  background-color: var(--primary);
  color: var(--dark-2);
  @media screen and (max-width: 1024px) {
    img {
      width: 50%;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 37%;
    }
  }
}
// bg colors
